import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AnalysisKey, RDSAnalysisData } from '../../../model/analysis-details.model';
import { AuthNewService } from '../../../service/auth-new.service';
import { Router } from '@angular/router';
import {delay} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  userAttributes
  signedInUser
  illegalUser:boolean
  showTable:boolean=false

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  dataSource: MatTableDataSource<RDSAnalysisData>;
  tableColumns: string[] = [ 'fileName','projectCode', 'analysisDate', 'status', 'userId','username','userrole'];
  @Input() searchResults: RDSAnalysisData[] = [];
  private currentLoginUser: Subscription;

  constructor(
    private authService: AuthNewService,
    private router: Router,
  ) {
  }

  ngOnInit() {
     this.showTable = true;
        this.dataSource = new MatTableDataSource(this.searchResults);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        setTimeout(() => this.dataSource.sort = this.sort);


  }

  getUrl(analysisType: string) {
    if (analysisType === 'SMR' || analysisType === 'LMR' || analysisType === 'ADA') {
      return '/default-layout/molecule-analysis';
    }
    else if (analysisType === 'qPCR') {
      return '/default-layout/qpcr-molecule-analysis';
    }
    else {
      return '/default-layout/notfound';
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openPDF() {
    const DATA: any = document.getElementById('reportData');
    html2canvas(DATA).then((canvas) => {
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('Analysis-Report.pdf');
    });
  }



  ngOnDestroy() {
    // Unsubscribe from the observable to avoid memory leaks
    // if (this.currentLoginUser) {
    //   this.currentLoginUser.unsubscribe();
    // console.log(this.currentLoginUser)
    // }
  }

}
