import {NgModule} from '@angular/core';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';

import {DefaultLayoutComponent} from './layouts/default/default.component';
import {ExtraLayoutComponent} from './layouts/extra/extra.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';

import {PageNotFoundComponent} from './pages/not-found/not-found.component';

import {PageSignIn1Component} from './pages/extra-pages/sign-in-1/sign-in-1.component';
import {Page500Component} from './pages/extra-pages/page-500/page-500.component';
import {Page404Component} from './pages/extra-pages/page-404/page-404.component';
import {Page403Component} from './pages/extra-pages/page403/page403.component';
import {
    FirstTimePasswordComponent,
    ForgotPasswordComponent,
    MfaComponent,
    ResetPasswordComponent,
    SigninComponent,
    SignoutComponent
} from './pages/auth';
import {HomeComponent} from './pages/home/home.component';
import {PreviousAnalysisComponent} from './pages/previous-analysis/previous-analysis.component';
import {AnalysisDetailsComponent} from './pages/analysis-details/analysis-details.component';
import {SmallMoleculeComponent} from './pages/small-molecule/small-molecule.component';
import {NewAnalysisComponent} from './pages/new-analysis/new-analysis.component';
import {SignupComponent} from './pages/auth/signup/signup.component';
import {UserComponent} from './pages/user/user.component';
import {TemplateComponent} from './pages/template/template.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailComponent } from './pages/blog/blog-detail/blog-detail.component';
import { UserPageComponent } from './user-page/user-page.component';
import { UserSignInComponent } from './pages/amplify-auth/user-sign-in/user-sign-in.component';
import { UserSignOutComponent } from './pages/amplify-auth/user-sign-out/user-sign-out.component';
import { UserNewPasswordComponent } from './pages/amplify-auth/user-new-password/user-new-password.component';
import { UserForgotPasswordComponent } from './pages/amplify-auth/user-forgot-password/user-forgot-password.component';
import { QpcrResultPageComponent } from './pages/small-molecule/qpcr-result-page/qpcr-result-page.component';
import { AuthLogComponent } from './pages/user/auth-log/auth-log.component';
import { AuthGuard } from '../app/shared/auth.guard';
import { Page401Component } from './pages/extra-pages/page401/page401.component';


const defaultRoutes: Routes = [
    {path: 'dashboard', component: DashboardComponent},
    {path: 'user-details', component: UserComponent,
    data: {
        shouldDetach: true
      }
    },
    {path: 'previous-analysis', component: PreviousAnalysisComponent,
    data: {
        shouldDetach: true
      }
    },
    {path: 'new-analysis', component: NewAnalysisComponent},
    {path: 'analysis-details/:id/:user', component: AnalysisDetailsComponent},
    {path: 'molecule-analysis/:id/:user', component: SmallMoleculeComponent},
    {path: 'qpcr-molecule-analysis/:id/:user', component: QpcrResultPageComponent},
    {path: 'template', component: TemplateComponent},
    {path: 'user-info', component: UserPageComponent},
    {path:'page-401', component:Page401Component},
    {path: 'auth-log', component: AuthLogComponent},
    {path: '**', component: Page404Component},
];

const extraRoutes: Routes = [
    {path: 'home',    component: HomeComponent},
    {path: 'signup',  component: SignupComponent},
    {path: 'signin',  component: UserSignInComponent},
    { path: 'blog', component: BlogComponent },
    { path: 'new-password', component: UserNewPasswordComponent },
    { path: 'blogDetail/:id', component: BlogDetailComponent },
    {path: 'signout',  component:  UserSignOutComponent},
    {path: 'mfa', component: MfaComponent},
    {path: 'sign-in', component: PageSignIn1Component},
    {path: 'page-404', component: Page404Component},
    { path: 'page-403', component: Page403Component },
    { path: 'page-500', component: Page500Component },
    
    {path: 'reset-password', component: ResetPasswordComponent},
    {path: 'first-time-password', component: FirstTimePasswordComponent},
    {path: 'forgot-password', component: UserForgotPasswordComponent},

];

const routes: Routes = [
    {
        path: '',
        redirectTo: '/extra-layout/home',
        pathMatch: 'full',
      
      
    },
    {
        path: 'default-layout',
        component: DefaultLayoutComponent,
        children: defaultRoutes,
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
       
       
    },
    {
        path: 'extra-layout',
        component: ExtraLayoutComponent,
        children: extraRoutes,
       
    },
    {
        path: '**',
        component: DefaultLayoutComponent,
        children: defaultRoutes,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})],
    exports: [RouterModule],
   
})
export class AppRoutingModule {
}
