import { Component, Inject, HostListener, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalysisDetails } from '../../../model/analysis-details.model';
import { TemplateService } from '../../../service/template.service';

// interface objData {
//   columns: string[];
//   description: string;
// }

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html'
})
export class HelpDialogComponent implements OnInit {

  analysis_data: AnalysisDetails;
  templateData: any;
  normalSamples = [];
  // food;

  constructor(private dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private templateService: TemplateService
  ) {
    this.analysis_data = data.name;
  
  }

  @HostListener('document:keyup.escape') onClose() {
    this.onCancel();
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnInit(): void {

  }


}
