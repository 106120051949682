import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {AuthService} from '../service';
import {Router} from '@angular/router';
import { AuthNewService } from '../service/auth-new.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router,
                private AuthService: AuthNewService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.AuthService.getAuthenticatedUser()?.getSession((err, session) => {
            if (err) {
                console.log('Error in getting Session forwarding to login page');
            
                return;
            }
            const credential = session.getIdToken().getJwtToken();
          
            req = req.clone({headers: req.headers.append('Authorization', credential)});
           
        });
        return next.handle(req);
    }
}
