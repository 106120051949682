// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  serverUrl: 'https://api.qpcr.devredthread.com',
  bucket: 'ariadne-dev-upload',
  userPool: {
    UserPoolId: 'us-east-2_kdavTyLd4',
    ClientId: '7h3secjus8ofhepoh88fe8ebul',
    region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:27ea473c-812a-4292-9bea-c784ce71e183',
  buckets: {
    'us-east-2': 'ariadne-dev-upload'
  },
  modules: {
    key: 'analysisType',
    values: [
        {
            'name': 'SMR',
            'desc': 'Small Molecule Report'
        },
        {
            'name': 'LMR',
            'desc': 'Large Molecule Report'
        },
        {
          'name': 'qPCR',
          'desc': 'qPCR'
        }
    ]
},
  defaultRegion: 'us-east-2',
  cognito: {
    identityPoolId: 'us-east-2:27ea473c-812a-4292-9bea-c784ce71e183', // REQUIRED - Amazon Cognito Identity Pool ID
    region: 'us-east-2', // REQUIRED - Amazon Cognito Region
    userPoolId: 'us-east-2_kdavTyLd4', // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '7h3secjus8ofhepoh88fe8ebul', // OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      region: 'us-east-2',
      bucket: 'ariadne-dev-upload', // OPTIONAL -  Amazon service region
    }
  }
};
const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_kdavTyLd4',
  'aws_user_pools_web_client_id': '7h3secjus8ofhepoh88fe8ebul',
  'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;
