import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupForm } from '../../types';
import {AuthService} from '../../../../service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-password',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  username: string;
  submissionError: string;
  statusCode: string;
  submitted = false;
  forgotPasswordForm: FormGroup;
  name

  constructor(private authService: AuthService, private router: Router, public formBuilder: FormBuilder, private route: ActivatedRoute) { }

  ngOnInit(){
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required]
    })

    this.name = this.route.snapshot.paramMap.get('name');
  }

  get formControls() { return this.forgotPasswordForm.controls; }
  forgotPassword() {
    this.submitted = true;
    this.username = this.forgotPasswordForm.value.username
      this.authService.forgotPassword(this.username,
      (err, statusCode) => {
        this.submitted = false;
        if (statusCode === AuthService.statusCodes.verificationCodeRequired) {
          this.router.navigate(['extra-layout/reset-password']);
        } else if (statusCode === AuthService.statusCodes.noSuchUser) {
          this.statusCode = statusCode;
          this.submissionError = 'Username is not valid.';
        } else if (statusCode === AuthService.statusCodes.unknownError) {
          this.statusCode = statusCode;
          this.submissionError = err.message;
        }
      });
  }
}
