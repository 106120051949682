import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { TestScheduler } from 'rxjs-compat';
import { SharedService } from '../../../layouts/shared-service';
import { AnalysisDetails,AnalysisDetailsADA } from '../../../model/analysis-details.model';
import { DropDownService } from '../../../service/drop-down.service';

@Component({
  selector: 'app-ada-report',
  templateUrl: './ada-report.component.html',
  styleUrls: ['./ada-report.component.scss']
})
export class AdaReportComponent implements OnInit {


  @Input() analysistype: string;
  @Input() editvalues:any;
  @Output() formvalue = new EventEmitter<any>();
  pageTitle = 'Analyze Report/Data';
  analysisFormGroup_ADA : FormGroup;
  maxDate;
  lts80_dialog: FormControl = new FormControl();
  analysisDetails: AnalysisDetails;
  subtype: AnalysisDetails;
  tableNamesList: any[];
  tableNames: any[];
  showTable: boolean = false;
  countTable: boolean = false;
  selectedTablesList = [];
  post: any;

  pattern1 = '^([a-zA-Z0-9\\s]+[-&/])*([a-zA-Z0-9\\s])+?$';
  stdConc_pattern = '^[-]?[0-9]+([,.][0-9]+)?$';
  stdConc_validator: boolean;
 
  openSecondMatStep: string;




  constructor(
    private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private dropDownService: DropDownService,
    private ref: ChangeDetectorRef
  ) {
    this._sharedService.emitChange(this.pageTitle);
   }

  ngOnInit(): void {
    this.createForm();
  }


  createForm() {
    this.analysisFormGroup_ADA = this.formBuilder.group({
      analysisType: ['ADA'],
      analysisSubtype: [null],
      ada_subtype: [null],
      analyteNames: this.formBuilder.array([this.formBuilder.control([])]),
      projectCode: [''],
      sampleMatrix: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      species: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      tukeyCoefficient: this.formBuilder.array([]),
      normalityTestData: [null],
      screening_cutpoint: ['', [Validators.pattern('^[0-9]+([.][0-9]+)?$')]],
      confirmatory_cutpoint: [null],
      concentrationUnit: [null],
      // negativeControlValues: ['Yes'],
      regressionType: [''],
      nabDataset: ['No'],
      CVThreshold: ['20'],
      PL: ['4pl']
    })

    this.ref.detectChanges();

    if(this.editvalues!=undefined)
    {
      let ADAvalue:AnalysisDetailsADA=new AnalysisDetailsADA()
      ADAvalue.analysisSubtype=this.editvalues.analysisSubtype;
      // ADAvalue.analysisSubtype_ADA=this.editvalues.analysisSubtype_ADA;
      ADAvalue.ada_subtype=this.editvalues.ada_subtype;
      ADAvalue.analysisType=this.editvalues.analysisType;
      ADAvalue.ada_subtype=this.editvalues.ada_subtype;
      ADAvalue.analyteNames=this.editvalues.analyteNames;
      ADAvalue.analyteNames=this.editvalues.analyteNames;
      ADAvalue.projectCode=this.editvalues.projectCode;
      ADAvalue.sampleMatrix=this.editvalues.sampleMatrix;
      ADAvalue.species=this.editvalues.species;
      ADAvalue.tukeyCoefficient=this.editvalues.tukeyCoefficient;
      ADAvalue.normalityTestData=this.editvalues.normalityTestData;
      ADAvalue.nabDataset=this.editvalues.nabDataset;
      ADAvalue.screening_cutpoint=this.editvalues.screening_cutpoint;
      ADAvalue.confirmatory_cutpoint=this.editvalues.confirmatory_cutpoint;
      ADAvalue.concentrationUnit=this.editvalues.concentrationUnit;
      ADAvalue.regressionType=this.editvalues.regressionType;
      ADAvalue.CVThreshold=this.editvalues.CVThreshold;
      ADAvalue.PL=this.editvalues.PL;

      for (let i = 1; i < this.editvalues.analyteNames.length; i++) {
        this.analyteNames.push(this.formBuilder.control(''));
      }

      
      this.analysisFormGroup_ADA.setValue(ADAvalue)
      
    }
  

  }

  get formControls1() { return this.analysisFormGroup_ADA.controls; }


  get analyteNames() {
      return this.analysisFormGroup_ADA.get('analyteNames') as FormArray;
   
  }



  onSubmit(post) {
    
      // tslint:disable-next-line: max-line-length
      this.analysisDetails = Object.assign({}, this.analysisFormGroup_ADA.value);
     
        if (this.analysisDetails.multipleAnalytes === 'Yes') {
          this.analysisDetails.multipleAnalytes = 'True'
        } else {
          this.analysisDetails.multipleAnalytes = 'False'
        }
  
      

      // this.analysisDetails.selectedTablesList = this.selectedTablesList.map(res =>
      //   res = res.description);
  
      this.openSecondMatStep = this.analysisDetails.analysisType;
  
      this.post = post;
      this.formvalue.emit(this.analysisDetails);
    
    }
  
  
    resetAnalysis() {
    
      // this.getData();
    }
  
    getDropDownValues(key) {
      return this.dropDownService.dropDownsMap.get(key);
    }
  



  add(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {

      // this.Tags.push(value);
      const control = this.analysisFormGroup_ADA.controls.tukeyCoefficient as FormArray;
      control.push(this.formBuilder.control(value.trim()));
    }

    if (input) {
      input.value = '';
    }
  }


  addAnalyte() {
    this.analyteNames.push(this.formBuilder.control(''));
  }

  removeAnalyte(index) {
    
      this.analyteNames.removeAt(index);
    }

  remove(i: number) {
    const control =  this.analysisFormGroup_ADA.controls.tukeyCoefficient as FormArray;
    control.removeAt(i);
  }

}
