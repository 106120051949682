import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../../layouts/shared-service';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/Rx';

import { DBService } from '../../service';
import { DownLoadService } from '../download';
import { AuthService } from '../../service';
import { User } from '../../model/types';
import { AnalysisKey, PreviousAnalysis, RDSAnalysisData } from '../../model/analysis-details.model';
import { DropDownService } from '../../service/drop-down.service';
import { AppLoaderService } from '../../service/app-loader/app-loader.service'
import Utils from '../../utility/Utils';
import { AuthNewService } from '../../service/auth-new.service';
import { Subscription } from 'rxjs/Rx';
import { delay } from 'rxjs/operators';
import { StorageService } from '../../service/storage.service';
import _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



@Component({
  selector: 'app-previous-analysis',
  templateUrl: './previous-analysis.component.html',
  styleUrls: ['./previous-analysis.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})



export class PreviousAnalysisComponent implements OnInit, OnDestroy {

  pageTitle = 'Previous Activities';
  signedInUser: any;
  signedInUserAccess: any;
  // previousAnalysisKeys: PreviousAnalysis[] = [];
  previousAnalysisKeys: RDSAnalysisData[] = [];
  pageLoaded = false;
  private rdsAnalysisDataSubscription: Subscription;
  private currentLoginUser: Subscription;
  startDate;
  endDate;
  maxDate = new Date();
  auditFlag: boolean = false;
  role;
  dateRangeStart: moment.Moment;
  dateRangeEnd: moment.Moment;
  userAttributes

  constructor(private _sharedService: SharedService,
    private authService: AuthNewService,
    private router: Router,
    private dbService: DBService,
    private storageService: StorageService,
    private loader: AppLoaderService,
    private route: ActivatedRoute) {
    this._sharedService.emitChange(this.pageTitle);
    this.role = this.storageService.get('access');


  }

  openDialog() {
    this.loader.open();
  }



  ngOnInit(): void {
    this.userAttributes = this.storageService.get('access')
    this.authService.getCurrentUser();
    this.currentLoginUser = this.authService.getCurrentLoginUser.subscribe((user: any) => {

      this.signedInUser = user;
      if (!this.signedInUser) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      }
      else if (this.userAttributes == 'QA') {
        this.pageLoaded = false
      }
    }
    )
    if (this.userAttributes == 'GA' || this.userAttributes == 'SU' || this.userAttributes == 'PI') {
      this.dbService.getAnalysis(this.userAttributes, this.startDate, this.endDate, this.auditFlag);
      this.rdsAnalysisDataSubscription = this.dbService.rdsAnalysisData.subscribe(
        (data: RDSAnalysisData[]) => {
          this.previousAnalysisKeys = data;
          this.pageLoaded = true
        });
    }
  }


  //   selectedDates(dateRangeStart, dateRangeEnd) {
  //     this.startDate = dateRangeStart.value;
  //     this.endDate = dateRangeEnd.value;
  //     this.passSelectedDates();

  //   }

  selectedDates() {
    this.startDate = this.dateRangeStart.format('MM/DD/YYYY');
    this.endDate = this.dateRangeEnd.format('MM/DD/YYYY');
    this.previousAnalysisKeys = []
    this.dbService.getAnalysis(this.role, this.startDate, this.endDate, this.auditFlag)
  }


  clearSelectedDates() {
    this.previousAnalysisKeys = [];
    this.dateRangeEnd = null;
    this.dateRangeStart = null
    this.startDate = 'none';
    this.endDate = 'none';
    this.dbService.getAnalysis(this.role, this.startDate, this.endDate, this.auditFlag)

  }

  closeDialog() {
    this.loader.close();
  }

  ngOnDestroy() {
    // Unsubscribe from the observable to avoid memory leaks

    if (this.rdsAnalysisDataSubscription) {
  this.rdsAnalysisDataSubscription.unsubscribe();
    }
    if (this.currentLoginUser) {
      this.currentLoginUser.unsubscribe();
    }
  }

}
